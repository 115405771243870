// 基本字段
let tableData = [
  {
    label: '序号',
    type: 'index',
    align: 'center',
    show: true,
    width: 100
  },
  {
    label: '消息标题',
    prop: 'title',
    show: true
  },
  {
    label: '监控指标',
    prop: 'targetName',
    show: true,
    width: 200
  },
  // {
  //   label: '消息内容',
  //   prop: 'msg',
  //   align: 'center',
  //   show: true,
  //   enableSlot: true,
  //   minWidth: 300
  // },
  {
    label: '创建人',
    prop: 'createUser',
    show: true,
    width: 200
  },
  {
    label: '创建时间',
    prop: 'createTime',
    show: true,
    enableSlot: true,
    width: 200
  },
  // {
  //   label: '操作',
  //   prop: 'msg',
  //   align: 'center',
  //   fixed: 'right',
  //   show: true,
  //   enableSlot: true,
  //   width: 120
  // }
  {
    prop: 'operators',
    label: '操作',
    fixed: 'right',
    align: 'right',
    width: 180,
    children: [
      {
        icon: 'el-icon-view',
        label: '查看',
        clickEvent: (thisVue, row) => {
          thisVue.handleOpen(row.msg)
        },
        showFilter: () => {
          return true
        }
      }
    ]
  }
]

let headData = {
  title: '消息列表'
}

let tableObj = {
  'tableData': tableData,
  'headData': headData
}

export default tableObj
