<template>
  <div>
    <table-list :columns="columns"
                :data="list"
                fit
                stripe
                :tableOption="{size: 'mini'}"
                :loading="loading"
                :pageData="pageData"
                :headData="headData"
                @handlePageChange="handlePageChange"
                @handleSizeChange="handleSizeChange">

      <!-- 创建时间 -->
      <template #createTime="{row}">
        {{ tf(row.createTime, 'YYYY-MM-DD HH:mm:ss') }}
      </template>

    </table-list>
    <!-- Dialog -->
    <el-dialog :title="title"
               width="1200px"
               :visible.sync="dialogVisible">
      <div class="info-div"
           v-if="info">
        <pre v-html="info"></pre>
      </div>
      <div v-else
           class="info-div">
        <span class="node-div">暂无数据</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TableList from '@/components/TableList'
import timeFormat from '@/utils/timeFormat.js'
import tableObj from './tableData.js'
export default {
  props: {
    datas: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    datas: {
      handler() {
        console.log('监听:', this.datas.value)
        this.pageData.currentPage = 1
        this.getData(1)
      },
      deep: false,
      immediate: true
    }
  },
  components: {
    TableList
  },
  data() {
    return {
      loading: false,
      dialogVisible: false, // 弹窗
      title: '',
      info: '',
      list: [], // 列表
      body: {},
      pageData: {
        size: 10, // 每页的数据量
        pageSize: 10, // 每页的条数跳转页面
        pageSizes: [10, 20, 50, 100], // 每页的条数跳转页面
        total: 0, // 数据总量
        layout: 'prev, pager, next, total, sizes, jumper, slot',
        currentPage: 1 // 当前页
      }, // 分页配置
      columns: tableObj.tableData,
      headData: tableObj.headData
    }
  },
  created() {},
  mounted() {},
  methods: {
    handleOpen(value) {
      this.title = '消息内容'
      console.log(value)
      // this.info = this.syntaxHighlight(JSON.parse(value))
      this.info = JSON.parse(JSON.stringify(value))
      this.dialogVisible = true
    },
    syntaxHighlight(_json) {
      var json = {}
      if (typeof _json !== 'string') {
        for (let item in _json) {
          var value = _json[item]
          if (value == null || value === undefined) {
            continue
          }
          json[item] = value
        }
        json = JSON.stringify(json, undefined, 2)
      }
      json = json.replace(/&/g, '&').replace(/</g, '<').replace(/>/g, '>')
      return json.replace(
        // eslint-disable-next-line no-useless-escape
        /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
        function (match) {
          var cls = 'number'
          if (/^"/.test(match)) {
            if (/:$/.test(match)) {
              cls = 'key'
            } else {
              cls = 'string'
            }
          } else if (/true|false/.test(match)) {
            cls = 'boolean'
          } else if (/null/.test(match)) {
            cls = 'null'
          }
          return '<span class="' + cls + '">' + match + '</span>'
        }
      )
    },
    // 时间转换
    tf(time, format) {
      return timeFormat(time, format)
    },
    async getData(currentPage) {
      let option = {
        page: currentPage - 1,
        size: this.pageData.size,
        alertId: this.$route.query.alertId || null,
        title: this.datas.title,
        targetId: this.datas.targetId,
        startCreateTime: Date.parse(this.datas.timeList[0]) || null,
        endCreateTime: Date.parse(this.datas.timeList[1]) || null

      }
      let { data, code } = await this.$api.warning.listMsg(option)
      if (code === 200) {
        // console.log('数据1:', data.content)
        this.pageData.total = data.total
        this.list = data.content
      }
    },
    handlePageChange(goPage) {
      this.pageData.currentPage = goPage
      this.getData(goPage)
    },
    // 配置每页条数
    handleSizeChange(number) {
      this.pageData.currentPage = 1
      this.pageData.size = number
      this.pageData.pageSize = number
      this.getData(1)
    }
  }
}
</script>

<style lang="scss" scoped>
.table-row:hover {
  text-decoration: underline;
  color: $--color-primary;
}
// 不换行省略
.shenglue {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
// js样式
.node-div {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.info-div {
  background: rgb(245, 245, 245);
  max-height: 600px;
  overflow-x: scroll;
}
.info-div::-webkit-scrollbar {
  height: 10px;
}
/*滚动条里面滑块*/
.info-div::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.2);
  background: #d3d3d3;
}
/*滚动条里面轨道*/
.info-div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 1);
  border-radius: 10px;
  background: #ffffff;
}
</style>
