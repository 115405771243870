<template>
  <div class="ym-page">
    <!-- 顶部组件 -->
    <search-form :form="form" @search="search"></search-form>
    <!-- 任务列表 -->
    <detail-list :datas="searchVal"></detail-list>
  </div>
</template>

<script>
import SearchForm from './component/SearchForm.vue'
import DetailList from './component/DetailList.vue'
export default {
  components: {
    SearchForm,
    DetailList
  },
  data() {
    return {
      // 表单
      form: {
        title: null,
        targetId: null, // 监控指标（默认全部）
        timeList: []
      },
      // 搜索
      searchVal: {
        title: null,
        targetId: null, // 监控指标（默认全部）
        timeList: []
      }
    }
  },
  created() {},
  mounted() {
  },
  methods: {
    search(form) {
      // 重置表单
      this.form = form
      // 搜索列表
      this.searchVal = form
    }
  }
}
</script>
